// node-modules
import { TweenLite, Power1 } from 'gsap/TweenMax'
import { ScrollToPlugin } from 'gsap/all'

// polyfills
import 'dom4'

// default components
import '@/components/preloader'
import '@/components/scrollToTop'
import '@/components/header'
import '@/components/footer'
import '@/components/modals'
import '@/components/tooltip'
import '@/components/animations'

// functional components
import { scrollDownInit } from '@/components/scrollDown'
import { menuToggle } from '@/components/hamburgerMenu'
import { initGoogleMaps } from '@/components/googleMaps'
import { sliderAdvantage, sliderPlans, sliderHalvesTexts, sliderResidences } from '@/components/swiperSlider'
import { Tabs } from '@/components/tabs'
import { AnimatedListLine } from '@/components/AnimatedListLine'
import { AnimatedListPointer } from '@/components/AnimatedListPointer'
import { sayHello } from '@/scripts/utils/sayHello'
import { initLazyLoad } from '@/components/lazy-load'
import { Form } from '@/components/Form'
import { customSelect } from '@/components/customSelect'

// assets
import 'styles/main.scss'
// import { DOCUMENT_ELEMENT } from '@/scripts/constants'

// eslint-disable-next-line
const plugins = [ScrollToPlugin]

TweenLite.defaultEase = Power1.easeOut
sayHello()

document.addEventListener('DOMContentLoaded', () => {
  // set css transition-duration to get it in JS
  const duration = getComputedStyle(document.documentElement)['transition-duration']
  document.documentElement.dataset.duration = parseFloat(duration)

  // Lazy load images
  // https://github.com/verlok/lazyload
  initLazyLoad()

  // init scroll-down button
  scrollDownInit()

  // init menu
  menuToggle()

  // init sliders
  sliderAdvantage()

  document.querySelectorAll('[data-role="slider-plans"]')
    .forEach(container => sliderPlans(container))

  document.querySelectorAll('[data-role="slider-residences"]')
    .forEach(container => sliderResidences(container))

  document.querySelectorAll('[data-role="slider-halves-texts"]')
    .forEach(container => sliderHalvesTexts(container))

  // init list-decoration
  document.querySelectorAll('[data-role*="animated-list-line"]')
    .forEach((list) => new AnimatedListLine(list))
  document.querySelectorAll('[data-role*="animated-list-pointer"]')
    .forEach(list => new AnimatedListPointer(list))

  // init tabs
  document.querySelectorAll('[data-role="tab-list-container"]')
    .forEach(el => new Tabs({
      mainContainer: el,
      lineController: el.querySelector('[data-role*="animated-list-line"]')
    }))

  // init google maps
  document.querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el))

  // selects
  document.querySelectorAll('[data-custom-select]')
    .forEach((el) => customSelect(el))

  // init form
  document.querySelectorAll('[data-role="contact-form"]')
    .forEach(element => new Form(element))
})
