// node-modules
import PubSub from 'pubsub-js'

// constants
import { SELECTORS } from '@/scripts/constants'

// assets
import './preloader.scss'

export const PRELOADER_EVENTS = {
  startLoading: 'PRELOADER:START',
  finishLoading: 'PRELOADER:END'
};

(function preloader () {
  const preloader = document.querySelector('[data-role="preloader"]')
  if (!preloader) {
    document.addEventListener('DOMContentLoaded', () => {
      PubSub.publish(PRELOADER_EVENTS.finishLoading)
    })
  } else {
    const load = (timeout = 2000) => {
      Array.from(preloader.children).forEach(child => {
        child.addEventListener('transitionend', (e) => {
          e.preventDefault()
          e.stopPropagation()
        })
      })

      setTimeout(() => {
        preloader.classList.add(SELECTORS.hidden)
      }, timeout)

      preloader.addEventListener('transitionend', () => {
        setTimeout(() => {
          PubSub.publish(PRELOADER_EVENTS.finishLoading)
        }, 200)
      })
    }
    document.addEventListener('DOMContentLoaded', () => {
      const shortPreloader = localStorage.getItem('shortPreloader') !== null
      const coverImg = document.querySelector('[data-animation="cover"] img')
      localStorage.setItem('shortPreloader', '')
      preloader.classList.add(SELECTORS[shortPreloader ? 'short' : 'loaded'])
      if (shortPreloader) {
        preloader.classList.add(SELECTORS.hidden)
        PubSub.publish(PRELOADER_EVENTS.finishLoading)
        return
      }
      if (!coverImg) {
        load()
        return
      }
      if (coverImg.complete) {
        load()
      } else {
        coverImg.addEventListener('load', () => load)
      }
    })
  }
})()
