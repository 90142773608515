const captchaStatus = {
  scriptAppended: false,
  widgetReady: false
}

export class Captcha {
  constructor ({ element, sitekey, formInstance }) {
    this.grecaptcha = null
    this.grecaptchaId = null
    this.sitekey = sitekey
    this.interval = null

    this.formInstance = formInstance
    this.element = element
    this.captcha = document.createElement('div')
    this.captcha.classList.add('g-recaptcha')

    this.API = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit'
    this.init()
  }

  execute () {
    this.grecaptcha.execute(this.grecaptchaId)
  }

  reset () {
    this.grecaptcha.reset(this.grecaptchaId)
  }

  callback (recaptchaToken) {
    console.log(recaptchaToken)
    this.element.querySelector('.g-recaptcha-response').value = recaptchaToken
    this.formInstance.validationSuccess()
  }

  render () {
    this.grecaptchaId = this.grecaptcha.render(this.captcha, {
      sitekey: this.sitekey,
      size: 'invisible',
      // the callback executed when the user solve the recaptcha
      callback: (recaptchaToken) => {
        this.callback(recaptchaToken)
      },
      'expired-callback': () => {
        this.reset()
      }
    })
  }

  watch () {
    this.interval = setInterval(() => {
      if (captchaStatus.widgetReady) {
        clearInterval(this.interval)
        this.grecaptcha = window.grecaptcha
        this.render()
      }
    }, 1000)
  }

  init () {
    this.element.appendChild(this.captcha)

    if (!captchaStatus.scriptAppended) {
      const script = document.createElement('script')
      captchaStatus.scriptAppended = true
      document.body.appendChild(script)
      script.src = this.API
      window.onloadCallback = () => {
        captchaStatus.widgetReady = true
      }
      this.watch()
    } else if (!captchaStatus.widgetReady) {
      this.watch()
    } else {
      this.grecaptcha = window.grecaptcha
      this.render()
    }
  }
}
