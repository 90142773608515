// node-modules
import LazyLoad from 'vanilla-lazyload'
import PubSub from 'pubsub-js'

export const LAZY_LOAD_EVENTS = {
  update: Symbol('LAZY-IMAGES:UPDATE'),
  loadForce: Symbol('LAZY-IMAGES:LOAD-FORCE'),
  imageLoaded: Symbol('LAZY-IMAGES:IMAGE-LOADED')
}

export function initLazyLoad () {
  const lazy = new LazyLoad({
    elements_selector: '[data-lazy-loading="true"]',
    threshold: 1000,
    callback_load (el) {
      PubSub.publish(LAZY_LOAD_EVENTS.imageLoaded, { lazyImage: el })
    }
  })

  PubSub.subscribe(LAZY_LOAD_EVENTS.update, () => {
    lazy.update()
  })

  PubSub.subscribe(LAZY_LOAD_EVENTS.loadForce, (msg, { lazyImage }) => {
    lazy.load(lazyImage, true)
  })
}
