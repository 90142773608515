// node-modules
import Tooltip from 'tooltip.js'

// assets
import './tooltip.scss'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-tooltip]')
    .forEach(element => new Tooltip(element, {
      title: () => element.dataset.title,
      placement: 'top'
    }))
})
