// node-modules
import 'letteringjs'
import PubSub from 'pubsub-js'
import ScrollMagic from 'scrollmagic'

// constants
import { SELECTORS } from '@/scripts/constants'
import { PRELOADER_EVENTS } from '@/components/preloader'

// other
import { controller } from '@/scripts/utils/scrollmagic'

// assets
import './animations.scss'

$('[data-split-text="lines"]')
  .lettering('lines')
  .children('span')
  .text((_, text) => text.trim())
  .lettering('words')
  .children('span')
  .lettering()
  .children('span')

document.querySelectorAll('[class^="line"]')
  .forEach(container => {
    const delay = getComputedStyle(container).getPropertyValue('--delay')
    const chars = container.querySelectorAll('[class^="char"]')
    chars.forEach((item, i) => {
      item.style.setProperty('--delay', `${parseFloat(delay) + i / Math.max(2 * chars.length, 20)}s`)
      item.style.setProperty('--duration', `${0.5}s`)
    })
  })

document.querySelectorAll('[data-animation="stagger-list"]')
  .forEach(container => {
    const delay = getComputedStyle(container).getPropertyValue('--delay')
    container.querySelectorAll('[data-animation="stagger-item"]')
      .forEach((item, i) => {
        item.style.setProperty('--delay', `${parseFloat(delay) + i / 10}s`)
      })
  })

const cover = document.querySelector('[data-animation="cover"]')
if (cover) {
  PubSub.subscribe(PRELOADER_EVENTS.finishLoading, () => {
    cover.querySelectorAll('[data-animation]')
      .forEach(el => el.classList.add(SELECTORS.loaded))
  })
}

document.querySelectorAll('[data-animation-container]')
  .forEach(container => {
    new ScrollMagic.Scene({
      triggerElement: container,
      triggerHook: 0.7,
      offset: +container.dataset.offset || 200
    })
      .on('enter', () => {
        container.querySelectorAll('[data-animation]')
          .forEach(element => element.classList.add(SELECTORS.loaded))
      })
      // .addIndicators()
      .addTo(controller)
  })
