// node-modules
import * as Swiper from 'swiper/dist/js/swiper'

// other
import { leadZero } from '@/scripts/utils/lead-zero'

// assets
import 'swiper/dist/css/swiper.min.css'
import './swiperSlider.scss'

const sliderOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 1000,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  keyboard: true
}

export function sliderAdvantage () {
  return new Swiper('[data-role="advantage-slider"]', {
    ...sliderOptions,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    effect: 'fade',
    pagination: {
      el: '[data-role="advantage-pagination"]',
      clickable: true
    }
  })
}

export function sliderPlans (sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    effect: 'coverflow',
    observer: true,
    observeParents: true,
    pagination: {
      el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
      clickable: true
    },
    navigation: {
      nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
      prevEl: sliderContainer.querySelector('[data-role="slider-prev"]')
    }
  })
}

export function sliderResidences (sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    effect: 'fade',
    observer: true,
    observeParents: true,
    pagination: {
      el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
      clickable: true
    },
    navigation: {
      nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
      prevEl: sliderContainer.querySelector('[data-role="slider-prev"]')
    }
  })
}

export function sliderHalvesImages (sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    keyboard: false,
    simulateTouch: false,
    effect: 'fade',
    touchEvents: false
  })
}

export function sliderHalvesTexts (sliderContainer) {
  const mainSwiper = new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    speed: 500,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
      prevEl: sliderContainer.querySelector('[data-role="slider-prev"]')
    }
  })

  const connectedElement = document.querySelector(`[data-role="${sliderContainer.dataset.connect}"]`)

  if (!connectedElement) {
    return
  }

  mainSwiper.controller.control = sliderHalvesImages(connectedElement)

  const { nextEl, prevEl } = mainSwiper.navigation

  const nextText = nextEl.querySelector('[data-role="count"]')
  const prevText = prevEl.querySelector('[data-role="count"]')

  const progressLine = sliderContainer.querySelector('[data-role="progress-line"]')

  const clamp = (n, min, max) => n < min ? min : n > max ? max : n

  const changeText = () => {
    const { activeIndex } = mainSwiper
    const { length } = mainSwiper.slides

    nextText.innerText = leadZero(clamp(activeIndex + 2, 1, length))
    prevText.innerText = leadZero(clamp(activeIndex + 1, 1, length))
  }

  const sliding = () => {
    if (!progressLine) {
      return
    }
    progressLine.classList.remove('waiting')
    progressLine.classList.add('sliding')
    progressLine.style.setProperty('transition-duration', `${mainSwiper.params.speed}ms`)
  }

  const waiting = () => {
    if (!progressLine) {
      return
    }
    progressLine.classList.add('waiting')
    progressLine.classList.remove('sliding')
    progressLine.style.setProperty('transition-duration', `${mainSwiper.params.autoplay.delay}ms`)
  }

  mainSwiper
    .on('slideChange', changeText)
    .on('transitionStart', sliding)
    .on('transitionEnd', waiting)

  changeText()
  waiting()
}
