// node-modules

import GoogleMapsLoader from 'google-maps'
// import Tooltip from 'tooltip.js'

// other
import { isIe } from '@/scripts/utils/detect-ie'
import mapStyles from './styles.json'

// assets
import './googleMaps.scss'

function getPoint (coords, google) {
  try {
    const { lat, lng } = JSON.parse(coords)
    return new google.maps.LatLng(lat, lng)
  } catch (e) {
    return false
  }
}

function addMarker (google, position, icon, origin, map, restOptions = {}) {
  const marker = new google.maps.Marker({
    position: position,
    icon: isIe() ? null : icon,
    optimized: false,
    origin: isIe() ? null : new google.maps.Point(...origin.split(' ')),
    ...restOptions
  })
  marker.setMap(map)
  return marker
}

function assignNearby (list, map, google) {
  try {
    const markerSize = 15
    const nearby = JSON.parse(list)
    const rect = {
      path: `M 
        ${-markerSize / 2},${-markerSize / 2} 
        ${markerSize / 2},${-markerSize / 2} 
        ${markerSize / 2},${markerSize / 2} 
        ${-markerSize / 2},${markerSize / 2} 
        z`,
      fillColor: '#6B6967',
      fillOpacity: 1,
      strokeOpacity: 0
    }

    nearby.forEach(element => {
      const markerPosition = getPoint(JSON.stringify(element.coords), google)
      const marker = addMarker(
        google,
        markerPosition,
        rect,
        `${markerSize / 2} ${markerSize / 2}`,
        map,
        {
          label: {
            text: `${element.title}`,
            color: 'white',
            fontSize: '8px',
            fontFamily: 'Gotham Pro, sans-serif'
          }
        }
      )
      const contentString = `<p>${element.content}</p>`

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 320,
        pixelOffset: new google.maps.Size(0, -10)
      })

      marker.addListener('mouseover', () => {
        infowindow.open(map, marker)
      })

      marker.addListener('mouseout', () => {
        infowindow.close()
      })
    })
  } catch (e) {
    console.log(e)
    return null
  }
}

export function initGoogleMaps (el) {
  const {
    key,
    options,
    coords,
    center,
    markerImage,
    markerOrigin = '10 14',
    nearby = '[]'
  } = el.dataset
  GoogleMapsLoader.KEY = key
  GoogleMapsLoader.VERSION = 'weekly'
  return GoogleMapsLoader.load((google) => {
    const markerPosition = getPoint(coords, google)
    const centerPosition = getPoint(center, google) || markerPosition
    const map = new google.maps.Map(el, {
      center: centerPosition,
      styles: mapStyles,
      ...JSON.parse(options)
    })
    const mainMarker = addMarker(
      google,
      markerPosition,
      markerImage,
      markerOrigin,
      map
    )

    const contentString = `<p>${el.dataset.markerContent}</p>`

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 320,
      pixelOffset: new google.maps.Size(0, -10)
    })

    mainMarker.addListener('mouseover', () => {
      infowindow.open(map, mainMarker)
    })

    mainMarker.addListener('mouseout', () => {
      infowindow.close()
    })

    assignNearby(nearby, map, google)
  })
}
