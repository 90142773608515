// node-modules
import 'dl-animate'
import PubSub from 'pubsub-js'

// constants
import { SELECTORS } from '@/scripts/constants'
import { LAZY_LOAD_EVENTS } from '@/components/lazy-load'
import { LIST_LINE_EVENTS } from '@/components/AnimatedListLine'

// other
import { createEvent } from '@/scripts/utils/create-event'

// assets
import './tabs.scss'

const defaults = {
  mainContainer: null,
  navItem: '[data-role*="tab-list-link"]',
  tabItem: '[data-role*="tab-list-target"]',
  extraControl: '[data-role="switch-tab"]',
  activeClass: SELECTORS.active,
  loadedClass: SELECTORS.loaded,
  lineController: false
}

export class Tabs {
  constructor (props) {
    const options = Object.assign({}, defaults, props)
    this.mainContainer = options.mainContainer
    this.navItem = options.navItem
    this.tabItem = options.tabItem
    this.extraControl = options.extraControl
    this.activeClass = options.activeClass
    this.loadedClass = options.loadedClass
    this.navItems = Array.from(this.mainContainer.querySelectorAll(this.navItem))
    this.tabItems = Array.from(this.mainContainer.querySelectorAll(this.tabItem))
    this.extraControls = Array.from(this.mainContainer.querySelectorAll(this.extraControl))
    this.navLine = options.navLine
    this.lineController = options.lineController
    this.animate = DLAnimate

    this.activeIndex = 0
    this.isAnimated = false
    this.duration = document.documentElement.dataset.duration * 1000
    this.init()
  }

  setAttribute () {
    this.navItems.forEach((item, i) => {
      item.dataset.index = i // eslint-disable-line no-param-reassign
      this.tabItems[i].dataset.index = i
      this.tabItems[i].classList.add(this.loadedClass)
    })
  }

  showActiveItem (newIndex) {
    this.animate.show(this.tabItems[newIndex], {
      name: 'tab',
      track: 'none',
      duration: this.duration,
      beforeEnter: () => {
        PubSub.publish(LAZY_LOAD_EVENTS.update)
      },
      afterEnter: () => {
        this.isAnimated = false
        this.activeIndex = newIndex
      }
    })
  }
  changeTab (newIndex) {
    if (this.isAnimated || newIndex === this.activeIndex) return false
    return this.animate.hide(this.tabItems[this.activeIndex], {
      name: 'tab',
      track: 'none',
      duration: this.duration,
      beforeLeave: () => {
        this.isAnimated = true
        this.navItems[newIndex].classList.add(this.activeClass)
        this.navItems[this.activeIndex].classList.remove(this.activeClass)
        if (this.lineController) this.lineController.dispatchEvent(createEvent(LIST_LINE_EVENTS.updatePosition))
      },
      afterLeave: () => this.showActiveItem(newIndex)
    })
  }

  addEvents () {
    const that = this;
    [...this.navItems, ...this.extraControls].forEach((item) => {
      item.addEventListener('click', function click (e) {
        e.preventDefault()
        that.changeTab(this.dataset.index)
      })
    })
  }

  init () {
    if (!this.navItems.length || !this.tabItems.length) return
    this.setAttribute()
    this.addEvents()
    this.showActiveItem(0)
    this.navItems[0].classList.add(this.activeClass)
    if (this.lineController) this.lineController.dispatchEvent(createEvent('updateLine'))
  }
}
