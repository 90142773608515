// constants
import { FORM_ELEMENTS, FORM_SELECTORS } from '@/components/Form'

// assets
import './InputBlock.scss'

export class InputBlock {
  constructor (element, formInstance) {
    this.element = element
    this.formInstance = formInstance
    this.label = this.element.querySelector(FORM_ELEMENTS.input_block__label)
    this.input = this.element.querySelector(FORM_ELEMENTS.input_block__input)
    this.error = this.element.querySelector(FORM_ELEMENTS.input_block__error)

    this.init()
  }

  showErrorMessage () {
    this.element.classList.add(FORM_SELECTORS.invalid)

    const errors = []

    for (const key in this.input.validity) {
      if (this.input.validity[key] && this.input.dataset[key]) {
        errors.push(this.input.dataset[key])
      }
    }
    this.error.innerHTML = errors.join(', ')
  }

  hideErrorMessage () {
    this.element.classList.remove(FORM_SELECTORS.invalid)
    this.error.innerHTML = ''
  }

  focusLabel (e) {
    this.element.classList.toggle(
      FORM_SELECTORS.focused,
      !!this.input.value ||
      e.type !== 'focusout' // keep input in active state if it is focused and has no value
    )
  }

  setupEvents () {
    ['focusin', 'input', 'change', 'focusout'].forEach((event) => {
      this.input.addEventListener(event, (e) => {
        this.focusLabel(e)
      })
    });

    ['invalid', 'focusout'].forEach(event => {
      this.input.addEventListener(event, (e) => {
        e.preventDefault()
        if (!this.input.validity.valid) {
          this.showErrorMessage()
        } else {
          this.hideErrorMessage()
        }
        if (e.type === 'invalid') {
          this.formInstance.scrollToInvalid()
        }
      })
    })
  }

  on (eventsArray, callback) {
    eventsArray.forEach(event => {
      this.input.addEventListener(event, callback)
    })
  }

  init () {
    this.element.classList.toggle(FORM_SELECTORS.required, this.input.required)
    this.setupEvents()
    this.focusLabel({ type: 'focusout' })
  }
}
