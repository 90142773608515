// constants
import { DOCUMENT_ELEMENT, KEY_CODES, SELECTORS } from '@/scripts/constants'

// other
import { toggleScrollbar } from '@/scripts/utils/toggleScrollbar'

// assets
import './hamburgerMenu.scss'

export function menuToggle () {
  let menuButton = document.querySelector('[data-role="menu-button"]')
  // let menuContainer = document.querySelector('[data-role="menu-container"]')
  let timeout = null

  const toggle = (allow) => {
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.menu_opened, allow)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      toggleScrollbar(allow)
      DOCUMENT_ELEMENT.classList.toggle(SELECTORS.has_modal, allow)
    }, allow ? 0 : 750)
  }

  const isActive = () => DOCUMENT_ELEMENT.classList.contains(SELECTORS.menu_opened)

  const setupEvents = () => {
    menuButton.addEventListener('click', (e) => {
      e.preventDefault()
      toggle(!isActive())
    })
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === KEY_CODES.esc && isActive()) {
        toggle(false)
      }
    })
  }

  setupEvents()
}
