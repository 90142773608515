// node-modules
import { TweenLite } from 'gsap/TweenLite'
import _debounce from 'lodash/debounce'

// constants
import { SELECTORS } from '@/scripts/constants'

export const LIST_LINE_EVENTS = {
  updatePosition: 'LIST_LINE:UPDATE'
}

export class AnimatedListLine {
  constructor (list) {
    this.list = list
    this.hover = this.list.dataset.hoverLine
    this.listItems = Array.from(this.list.querySelectorAll('[data-role*="list-item"]'))
    this.line = this.list.querySelector('[data-role="forward-line"]')
    this.duration = document.documentElement.dataset.duration
    this.activeClass = SELECTORS.active
    this.hiddenClass = SELECTORS.hidden

    this.activeIndex = 0

    this.forwardHovered = this.forwardHovered.bind(this)
    this.backToActive = this.backToActive.bind(this)
    this.backToActive_debounced = _debounce(this.backToActive, 100)
    this.init()
  }

  setStyle (target) {
    const {
      offsetLeft, offsetWidth, offsetHeight, offsetTop
    } = target
    TweenLite.to(this.line, this.duration, { x: offsetLeft, width: offsetWidth, y: offsetHeight + offsetTop })
  }

  forwardHovered (e) {
    this.line.classList.remove(this.hiddenClass)
    const { target } = e
    this.activeIndex = this.listItems.indexOf(target)
    return this.setStyle(target)
  }

  backToActive () {
    const active = this.list.querySelector(`.${this.activeClass}`)
    let target = active && active.closest('[data-role*="list-item"]')
    if (!target) {
      this.line.classList.add(this.hiddenClass)
      target = this.listItems[this.activeIndex]
    }
    return this.setStyle(target)
  }

  destroy () {
    window.removeEventListener('resize', this.backToActive_debounced)
  }

  init () {
    if (this.hover) {
      this.listItems.forEach((item) => {
        item.addEventListener('mouseenter', this.forwardHovered)
        item.addEventListener('mouseleave', this.backToActive)
      })
    }

    this.backToActive()
    this.list.addEventListener(LIST_LINE_EVENTS.updatePosition, this.backToActive)

    window.addEventListener('resize', this.backToActive_debounced)
  }
}
