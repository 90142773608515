// node-modules
import ScrollMagic from 'scrollmagic'
import { TweenLite } from 'gsap/TweenLite'

// constants
import { ROOT_CLASS, SELECTORS } from '@/scripts/constants'

// other
import { controller } from '@/scripts/utils/scrollmagic'

// assets
import './scrollToTop.scss'

(function scrollToTop () {
  const scrollToTop = document.createElement('div')
  const content = document.querySelector('[data-role="content"]')
  if (!content || content.classList.contains('no-scroll-top')) return false

  scrollToTop.className = `${ROOT_CLASS} scroll-to-top`
  scrollToTop.dataset.role = 'scroll-to-top'
  document.body.appendChild(scrollToTop)
  scrollToTop.addEventListener('click', () => {
    TweenLite.to(window, 0.8, { scrollTo: { y: 0, autoKill: false } })
  })

  return new ScrollMagic.Scene({
    triggerElement: content,
    triggerHook: 'onLeave',
    offset: 200
  })
    .setClassToggle(scrollToTop, SELECTORS.active)
    // .addIndicators({ name: 'Scroll to top' })
    .addTo(controller)
})()
