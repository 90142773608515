import './scrollDown.scss'
import { TweenLite } from 'gsap/TweenLite'

export function scrollDownInit () {
  let scrollDown = document.querySelectorAll('[data-role="button-scroll"]')

  function getElem (item) {
    item.addEventListener('click', (event) => {
      event.preventDefault()
      let id = item.hash
      console.log(id)
      let nextSection = document.querySelector(id)
      let scrollSection = nextSection.offsetTop
      TweenLite.to(window, 0.8, { scrollTo: { y: scrollSection, autoKill: false } })
    })
  }

  if (scrollDown) {
    scrollDown.forEach(getElem)
  }
}
